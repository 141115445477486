$buttons: (
  (button-regular-blue,
    inline-block,
    12,
    $font-semi-bold,
    uppercase,
    1.2,
    13px 30px,
    center,
    4px,
    $white,
    map.get($colors, "blue"),
    2px solid map.get($colors, "blue"), 
    map.get($colors, "blue"), 
    transparent,
    2px solid map.get($colors, "blue")
  ),
  (button-alt-yellow,
    inline-block,
    12,
    $font-semi-bold,
    uppercase,
    1.2,
    13px 30px,
    center,
    4px,
    map.get($colors, "yellow"),
    transparent,
    2px solid map.get($colors, "yellow"), 
    $white, 
    map.get($colors, "yellow"),
    2px solid map.get($colors, "yellow")
  ),  
  (button-alt-black,
    inline-block,
    12,
    $font-semi-bold,
    uppercase,
    1.2,
    13px 30px,
    center,
    4px,
    map.get($colors, "black"),
    transparent,
    2px solid map.get($colors, "black"), 
    $white, 
    map.get($colors, "black"),
    2px solid map.get($colors, "black")
  )
);

@each $label,
  $display,
  $font-size,
  $font-family,
  $text-transform,
  $line-height,
  $padding,
  $text-align,
  $border-radius,
  $color,
  $background-color,
  $border,
  $hover-color,
  $hover-background,
  $hover-border in $buttons {
  .#{$label} {
    background-color: $background-color;
    border: $border;
    border-radius: $border-radius;
    color: $color;
    display: $display;
    font-family: $font-family;
    line-height: $line-height;
    padding: $padding;
    text-align: $text-align;
    text-transform: $text-transform;
    transition: all .2s ease-in-out;
    @include font-size($font-size);

    &:hover,
    &:focus {
      background-color: $hover-background;
      color: $hover-color;
      border: $hover-border;
    }
  }
}

.button-add {
  align-items: center;
  color: map.get($colors, 'blue');
  border-radius: 3px;
  background-color: lighten(map.get($colors, 'grey'), 15%);
  border: 1px solid map.get($colors, 'grey');
  font-family: $font-bold;
  display: flex;
  padding: 4px 10px;
  transition: all .2s ease-in-out;

  > span {
    display: block;
    line-height: 1em;
    margin-right: 5px;
  } 

  &:hover {
    background-color: map.get($colors, 'blue');
    border-color: map.get($colors, 'blue');
    color: $white;
  }
}

.button {
  &--mobile {
    cursor: pointer;
    display: block;
    margin: 0 auto;
    height: 20px;
    position: relative;
    transform: rotate(0deg);
    width: 40px;
    z-index: 2;
    @include transition(all, 0.2s, ease-in-out);
  
    span {
      background: $white;
      display: block;
      height: 4px;
      left: 0;
      border-radius: 5px;
      opacity: 1;
      position: absolute;
      transform: rotate(0deg);
      width: 100%;
      @include transition(all, 0.2s, ease-in-out);
    }
  
    span:nth-child(1) {
      top: 0;
    }
  
    span:nth-child(2),
    span:nth-child(3) {
      top: 9px;
    }
  
    span:nth-child(4) {
      top: 18px;
    }  
  }

  &--scroll-top {
    bottom: 30px;
    position: fixed;
    right: 30px;
    z-index: 99;
  
    @include media-breakpoint-down(sm) {
      bottom: 15px;
      right: 15px;
    }
    
    &:hover,
    &:focus {}  
  }
}

.menu-open {
  .button--mobile {
    span:nth-child(1) {
      left: 50%;
      top: 18px;
      width: 0;
    }
  
    span:nth-child(2) {
      transform: rotate(45deg);
    }
  
    span:nth-child(3) {
      transform: rotate(-45deg);
    }
  
    span:nth-child(4) {
      left: 50%;
      top: 18px;
      width: 0;
    }
  }
}