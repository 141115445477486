$sprites : (
  (scroll-top, 30px, 30px, 0 0),
  (arrow-left, 40px, 40px, -30px 0),
  (arrow-right, 40px, 40px, -70px 0),
  (facebook-yellow, 30px, 30px, -110px 0),
  (instagram-yellow, 30px, 30px, -140px 0),
  (facebook-blue, 30px, 30px, -110px -30px),
  (instagram-blue, 30px, 30px, -140px -30px),
  (checkmark-yellow, 30px, 30px, -170px 0),
  (checkmark-black, 30px, 30px, -200px 0),
  (quote-left, 30px, 30px, -230px 0),
  (quote-right, 30px, 30px, -260px 0)
);

.sprite {
	background-image: url('assets/icons/sprite.png');
	background-repeat: no-repeat;
	display: inline-block;
	vertical-align: text-top;

  @each $label, $width, $height, $background-position in $sprites {
    &.#{$label} {
      width: $width;
      height: $height;
      background-position: $background-position;
    }
  }
}

.sprite {
  &.facebook-yellow,
  &.instagram-yellow,
  &.facebook-blue,
  &.instagram-blue,
  &.arrow-left,
  &.arrow-right  {
    transition: all .1s ease-in-out;

    &:hover {
      transform: scale(.95) translateY(-2px);
    }
  }
}