#header {
	background-color: $white;
	box-shadow: 0 0 5px rgba(0, 0, 0, .2);
	position: relative;
	padding: 0;
	width: 100%;
	z-index: 10;
	@include transition(all, 0.2s, ease-in-out);

	@include media-breakpoint-down(md) {
		.container {
			position: relative;
			max-width: 100%;
			padding-right: 70px;
		}
	}

	@include media-breakpoint-down(sm) {
		.logo {
			display: block;
			margin-top: 10px;
			width: 120px;
		}
	}

}

.header-top {
	background-color: map.get($colors, 'black');
	padding: 15px 0;

	.list {
		li {
			display: inline-block;
			vertical-align: middle;
		}
	}

	@include media-breakpoint-down(lg) {
		a[class^="button-"] {
			@include font-size(10);
		}
	}
	@include media-breakpoint-down(md) {
		a[class^="button-"] {
			@include font-size(10);
			padding: 10px;
		}
	}
	@include media-breakpoint-down(sm) {
		padding: 40px 0 10px 0;
		
		a[class^="button-"] {
			@include font-size(9);
			padding: 5 7.5px;
		}
		
		.list {
			border-bottom: 1px solid rgba(255, 255, 255, .2);
			left: 0;
			padding: 5px 15px;
			position: absolute;
			top: -40px;
			width: 100%;
		} 
	}
}

#navigation-main {
	align-items: center;
	display: flex;

	.item {
		flex-grow: 1;
		height: 100%;
	}

	.link {
		color: map.get($colors, 'black');
		display: block;
		line-height: 1.2em;
		padding: 25px 10px;
		position: relative;
		text-align: center;
		text-transform: uppercase;
		@include font-size(13);

		&:after {
			content: "";
			background-color: map.get($colors, 'blue');
			bottom: 15px;
			display: block;
			height: 2px;
			left: 50%;
			margin-left: -10px;
			opacity: 0;
			position: absolute;
			width: 20px;
			transition: all .2s ease-in-out;
		}
		
		&:focus,
		&:hover,
		&.active {
			&:after {
				bottom: 17px;
				opacity: 1;
			}
		}
	}

	@include media-breakpoint-down(xl) {
		.item {
			.link {
				display: block;
				@include font-size(12.5);
			}
		}
	}

	@include media-breakpoint-down(lg) {
		.item {
			.link {
				@include font-size(11);
			}
		}
	}

	@include media-breakpoint-down(md) {
		padding: 15px 0;
		width: calc(100% + 70px);
		.item {
			.link {
				font-family: $font-bold;
				padding: 15px 10px;
				@include font-size(13);
				
				&:after {
					bottom: 10px;
				}
			}
		}
	}


	.navigation-sub {
		.item-sub {
			text-align: center;
			&:not(:first-child) {
				margin-top: 10px;
			}
		}

		.link-sub {
			display: block;
			font-family: $font-semi-bold;
			text-transform: uppercase;
			line-height: 1.2em;
			@include font-size(11);

			&:focus,
			&:hover,
			&.active {
				font-family: $font-bold;
				color:  map.get($colors, 'blue');
			}
		}


		@include media-breakpoint-down(md) {
			padding: 10px 0;
			.item-sub {
				.link-sub {
					padding: 3px 0;
					@include font-size(12);
				}
			}
		}


	}

	@include media-breakpoint-down(lg) {
		display: none;
	}
}

@include media-breakpoint-up(md) {
	#navigation-main {
		display: flex !important;
	}

	#button--mobile {
		display: none;
	}
}

#button--mobile {
	position: absolute;
	top: 30px;
	right: 15px;
	
	@include media-breakpoint-down(sm) {
		top: 60px;
	}
}

.navigation-sub {
	background-color: map.get($colors, 'white');
	display: none;
	height: auto;
	left: auto;
	opacity: 1;
	position: relative;
	top: auto;
}

li {
	&[data-navsub] {
		position: relative;
	}
}

@include media-breakpoint-up(md) {
	li {
		&[data-navsub] {
			&.focus {
				> .navigation-sub {
					display: block;
					height: auto;
					opacity: 1;
					top: 66px;
					padding: 15px;
					visibility: visible;
				}
			}
		}
	}

	.navigation-sub {
		background-color: map.get($colors, 'white');
		box-shadow: 0 3px 5px -2px rgba(0, 0, 0, 0.2);
		display: none;
		border-radius: 0 0 4px 4px;
		height: 0;
		left: -15px;
		opacity: 0;
		overflow: hidden;
		padding: 0;
		position: absolute;
		width: calc(100% + 30px);
		z-index: 99;
		@include transition(all, 0.1s, ease-in-out);

		@include media-breakpoint-down(xl) {
			margin-top: 14px;
		}
		@include media-breakpoint-down(lg) {
			margin-top: 11px;
		}
	}
}

body {
	&.header-small {
		@include media-breakpoint-up(lg) {
			#header {
				position: fixed;
				left: 0;
				width: 100%;
			}
		}
		@include media-breakpoint-down(lg) {
			padding-top: 0 !important;
			#header {
				top: auto !important
			}
		}
	}
}

#header-page {
	background-color: map.get($colors, 'yellow');
	color: map.get($colors, 'white');
	padding: 70px 0;
	position: relative;
	text-align: center;

	.container {
		position: relative;
		z-index: 2;
	}

	&:after {
		content: "";
		background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.15) 100%);
		bottom: 0;
		left: 0;
		height: 70%;
		position: absolute;
		width: 100%;
		z-index: 1;
	}

	@include media-breakpoint-down(lg) {
		padding: 50px 0;
	}

	@include media-breakpoint-down(md) {
		padding: 40px 0;
	}

	@include media-breakpoint-down(sm) {
		padding: 30px 0;

		.title-1 {
			@include font-size(30);
		}
	}
}