.parallax-mirror {
	z-index: 0 !important;
}

.parallax-wp {
	@extend .clearfix;
	overflow: hidden;
	position: relative;
	height: 300px;
	background: transparent;
}