#slick-testimonial-wp {
	position: relative;

	.slick-dots {
		button {
			background-color: map.get($colors, 'black');
		}
		.slick-active {
			button {
				background-color: map.get($colors, 'white');
				&:focus,
				&:hover {
					background-color: map.get($colors, 'white');
				}
			}
		}
	}

	#slick-testimonial {
		width: calc(100% - 60px);
		padding-left: 60px;

		.slick-dots {
			display: none !important;
		}

		.text-3 {
			text-align: justify;
		}
	}

	@include media-breakpoint-down(md) {
		#slick-testimonial {
			padding-left: 0;
			width: 100%;

			.slick-dots {
				display: block !important;
			}
		}


	}


	.arrow-prev,
	.arrow-next {
		margin-top: -20px;
		position: absolute;
		top: 50%;
	}

	.arrow-prev {
		left: 0;
	}

	.arrow-next {
		right: 0;
	}

	@include media-breakpoint-down(md) {
		.arrow-prev,
		.arrow-next {
			display: none !important;
		}
	}

}
#slick-logos-wp {
	position: relative;

	.item {
		padding: 0 15px;
	}

	.item img {
		margin: 0 auto;
	}

	.mask-left,
	.mask-right {
		width: 100px;
		z-index: 1;
		height: 100%;
		position: absolute;
		top: 0;
		background: -moz-linear-gradient(left, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
		background: -webkit-linear-gradient(left, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
		background: linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
	}

	.mask-left {
		left: 0;
	}

	.mask-right {
		right: 0;
		transform: rotate(180deg);
	}
	@include media-breakpoint-down(sm) {
		.mask-left,
		.mask-right {
			width: 75px;
		}
	}
}

#slick-home-wp {
	position: relative;
	z-index: 3;

	.slick-dots {
		bottom: 30px;
		position: absolute;
		text-align: center;
		width: 100%;
	}

	.item {
		background-size: cover;
		background-position: center top;
		position: relative;
		min-height: 800px;

    @include media-breakpoint-down(xxxl) {
      min-height: 500px;
    }


		&:after {
			content: "";
			background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.7) 100%);
			bottom: 0;
			left: 0;
			height: 300px;
			position: absolute;
			width: 100%;
			z-index: 1;
		}

		.testimonial-wrapper {
			bottom: 0;
			color: $white;
			left: 50%;
			margin-left: -400px;
			position: absolute;
			margin-bottom: 70px;
			text-align: center;
			width: 800px;
			z-index: 2;

			.title-2 {
				position: relative;
				&:after,
				&:before {
					content:"";
					@extend .sprite;
				}

				&:before {
					@extend .quote-left;
					margin: -5px 10px 0 0;
				}

				&:after {
					@extend .quote-right;
					margin: 5px 0 0 10px;
				}
			}

			.text {
				font-family: $font-bold;
				@include font-size(20);
			}
		}

		@include media-breakpoint-down(md) {
			&:after {
				height: 100%;
			}
			.testimonial-wrapper  {
				left: 0;
				margin-left: 0;
				width: 100%;
				padding: 0 30px;
			}
		}
	}

	.slick-slide {
		.testimonial-wrapper {
			opacity: 0;
			transform: translateY(-30px) scale(.9);
			@include transition(all, 0.2s, ease-in-out, 0.5s);
		}
		&.animation {
			.testimonial-wrapper {
				opacity: 1;
				transform: translateY(0) scale(1);
			}
		}
	}

}

.slick-slide {
	outline: none !important;
}

.slick-vertical .slick-slide {
	border: none !important;
}

.slick-dots {
	margin-top: 15px;
	text-align: center;

	li {
		display: inline-block;
		vertical-align: top;
		margin: 0 2px;
	}

	button {
		width: 10px;
		height: 10px;
		margin-right: 5px;
		background-color: map.get($colors, 'blue');
		border-radius: 100%;
		transition: all 0.2s ease-in-out;
		overflow: hidden;
		text-indent: -999em;
	}

	.slick-active {
		button {
			background-color: map.get($colors, 'white');
			&:focus,
			&:hover {
				background-color: map.get($colors, 'white');
			}
		}
	}
}

#slick-wrapper {
	.item {}

	#slick-arrows {

		.arrow-next,
		.arrow-prev {}

		.arrow-prev {}

		.arrow-next {}
	}

	.slick-dots {}


	@include media-breakpoint-down(lg) {}

	@include media-breakpoint-down(md) {}

	@include media-breakpoint-down(sm) {}
}
