.item-contact {
  .title-contact {
    position: relative;
    padding-left: 25px;
    font-family: $font-bold;
    text-transform: uppercase;
    @include font-size(16);
  }

  .title-contact .material-icons {
    position: absolute;
    color: map.get($colors, 'blue');
    top: 1px;
    left: 0;
    @include font-size(18);
  }

  .list {
    margin-top: 20px;
  }

  .list li {
    display: block;
    position: relative;
    margin-bottom: 8px;
    color: map.get($colors, 'blue-light');
    line-height: 1.2em;
    word-break: break-word;
    font-family: $font-regular;
    @include font-size(14);
    
    .material-icons {
      position: absolute;
      color: map.get($colors, 'blue');
      top: 1px;
      left: -10px;
      width: 4px;
      height: 1px;  
      transition: all .2s ease-in-out;
      @include font-size(18);
    }

    a {
      display: block;

      &:hover {
        color: map.get($colors, 'blue');
        .material-icons {
          transform: translateX(-5px);
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    display: inline-block;
    width: 100%;
    vertical-align: top;
  }
}

.text-agency {
  border-top: 1px solid lighten(map.get($colors, 'blue'), 30%);
  padding-top: 40px;
  margin-top: 50px;
  @include font-size(18);
  
  @include media-breakpoint-down(md) {
    border: 0;
    padding-top: 0;
    margin-top: 25px;  
    @include font-size(16);
  }
}

.item-agency {
  color: map.get($colors, 'blue-light') !important;
  display: flex;
  font-family: $font-bold;
  @include font-size(20);
  
  .material-icons {
    color: map.get($colors, 'blue');
    margin-right: 10px;
    @include font-size(30);
  }
}