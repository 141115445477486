#footer {
	background-color: map.get($colors, 'black');
	color: $white;

	.link {
		display: block;
		transition: all .2s ease-in-out;
		&:hover {
			color: map.get($colors, 'blue-dark');
			transform: translateX(5px);
		}
	}

	.link-underline {
		&:hover {
			color: map.get($colors, 'blue-dark');
			text-decoration: underline;
		}
	}
	

	@include media-breakpoint-down(lg) {}

	@include media-breakpoint-down(md) {}

	@include media-breakpoint-down(sm) {}
}