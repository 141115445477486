@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/MaterialIcons-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat_Regular';
  font-style: normal;
  font-weight: normal;
  src: url('fonts/Montserrat-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat_SemiBold';
  font-style: normal;
  font-weight: normal;
  src: url('fonts/Montserrat-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat_Bold';
  font-style: normal;
  font-weight: normal;
  src: url('fonts/Montserrat-Bold.woff') format('woff');
}     

$font-regular: 'Montserrat_Regular', sans-serif;
$font-semi-bold: 'Montserrat_SemiBold', sans-serif;
$font-bold: 'Montserrat_Bold', sans-serif;

$fonts: (
  (title-1, 42, $font-bold, normal, 1.2, title),
  (title-2, 24, $font-bold, normal, 1.4, title),
  (title-3, 16, $font-bold, normal, 1.2, title),
  (title-4, 12, $font-bold, normal, 1.2, title),
  (text-1, 20, $font-regular, normal, 1.2, text),
  (text-2, 12, $font-bold, normal, 1.4, text),
  (text-3, 12, $font-regular, normal, 1.4, text),
  (text-4, 11, $font-bold, normal, 1.4, text),
  (text-5, 13, $font-regular, normal, 1.4, text),
);

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px; 
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
 }


html {
  color: $black;
  font-size: #{$font-size}px !important;
}

body {
  font-family: $font-semi-bold;
}

.text-justify {
  text-align: justify;
}

@each $label,
$font-size,
$font-weight,
$font-style,
$line-height,
$type in $fonts {
  .#{$label} {
    line-height: $line-height;
    font-style: $font-style;
    font-family: $font-weight;
    @include font-size($font-size);

    &.with-border {
      position: relative;

      &.center {
        &:after {
          margin-left: auto;
          margin-right: auto;
        }
      }

      @include media-breakpoint-down(lg) {
        &.center-lg {
          &:after {
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
            
      
      &:after {
        content: "";
        display: block;
        height: 2px;
        margin-top: 5px;
        width: 26px;
      }

      &.blue {
        &:after {
          background-color: map.get($colors, 'blue');
        }
      }

      &.white {
        &:after {
          background-color: map.get($colors, 'white');
        }
      }

      &.black {
        &:after {
          background-color: map.get($colors, 'black');
        }
      }

      &.yellow {
        &:after {
          background-color: map.get($colors, 'yellow');
        }
      }
    }

    @if $type ==title {
      @include media-breakpoint-down(md) {
        @include font-size(calc(math.div($font-size, 1.1)));
      }
    }

    @include media-breakpoint-down(sm) {
      @if type == text and size > 15 {
        @include font-size(calc(math.div($font-size,1.2)));
      } 
    }
  }
}