$colors: (
  'black': #282828,
  'white': #FFFFFF,
  'grey': #CCCCCC,
  'grey-dark': #727272,
  'error': #a94442,
  'blue': #00B6D5,
  'yellow': #FDB616,
);

@each $name,
$value in $colors {
  .color-#{$name} {
    color: $value;
  }

  .background-#{$name} {
    background-color: $value;
  }

}