.wysiwyg-wrapper {
  @include font-size(14);
  >* {
    &:first-child {
      margin-top: 0 !important;
    }

    *:last-child {
      margin-bottom: 0 !important;
    }
  }

  @for $i from 1 through 3 {
    h#{$i} {
      text-align: center;
      @extend .title-#{$i};
      @extend .with-border;
      @extend .center;
      @extend .blue;
    }
  }

  strong {
    font-family: $font-bold;
  }

  h1,
  h2,
  h3,
  h4,
  ol,
  ul {
    margin: 20px 0 25px;
  }

  .headline {
    @extend .text-1;
  }

  p {
    margin-bottom: 15px;
  }

  .highlight-wrapper {
    background-color:  map.get($colors, 'yellow');
    color: map.get($colors, 'black');
    display: block;
    margin: 50px auto;
    border-radius:  30px 0 30px 0;
    padding: 50px;
    width: 100%;
    clear: both;

    ul {
      li {
        &:before {
          @extend .checkmark-black;
        }
      }
    }
    ol {
      li {
        &:before {
          border-color: map.get($colors, 'black');
          color: map.get($colors, 'black');
        }
      }
    }
  }

  ol,
  ul {
    counter-reset: ol-counter;
    padding-left: 0;

    li {
      display: block;
      position: relative;
      padding-left: 30px;
      margin-bottom: 15px;
    }
  }

  ul {
    li {
      &:before {
        content: "";
        @extend .sprite;
        @extend .checkmark-yellow;
        top: -6px;
        left: -3px;
        position: absolute;
      }
    }
  }

  ol {
    li {
      &:before {
        border-radius: 100%;
        border: 1px solid map.get($colors, 'yellow');
        content: counter(ol-counter)"";
        color: map.get($colors, 'yellow');
        counter-increment: ol-counter;
        left: 0;
        position: absolute;
        top: 0;
        height: 20px;
        width: 20px;
        text-align: center;
        @include font-size(12);
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  a {
    color: map.get($colors, 'black');
    text-decoration: underline;
  }

  .button-wrapper {
    clear: both;
    margin: 30px 0;

    a {
      text-decoration: none;
      @extend .button-regular-blue;
    }
  }

  img {
    max-width: 100%;
    height: auto;

    &.img-left {
      float: left;
      margin: 5px 30px 15px 0;
    }

    &.img-right {
      float: right;
      margin: 5px 0 15px 30px;
      &:after {
        content:"";
        clear: both;
      }
    }

    &.img-center {
      margin: 50px auto;
      display: block;
    }
  }

  @include media-breakpoint-down(md) {
    .highlight-wrapper {
      margin: 20px 0;
      padding: 30px;
      width: 100%;
    }

    h2,
    h3,
    ol,
    ul,
    .highlight-wrapper,
    img,
    .button-wrapper {
      margin: 20px 0 !important;
    }

    img {

      &.img-left,
      &.img-right {
        display: block;
        float: none;
      }
    }
  }
}